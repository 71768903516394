import { ARTIST_STATUS, MAX_UNDERESTIMATED_FACTOR } from 'shared/constants/app';
import { AuctionPriorityLabels, AuctionStatusLabels } from './raw-auction';
import { Popover, Tooltip } from 'antd';

import React from 'react';
import { formatDate } from 'shared/helpers/format';
import { getAuthorUrl } from 'shared/tools/url';
import { isNil } from 'lodash';
import { mediumFinals } from 'cleaning-lots/constants/lot-fields';

import { mediumFinalsMapping } from './lot-fields';
import {
  cleanerReviewStatuses,
  publishStatuses,
  reviewStatuses,
} from 'cleaning-lots/constants/lot-fields';

const renderPriceEstimateColumn = style => (value, row) => {
  const underestimatedFactor =
    row.price_estimate_avg && row.price_sold && row.price_estimate_avg / row.price_sold;

  if (underestimatedFactor && underestimatedFactor >= MAX_UNDERESTIMATED_FACTOR) {
    return (
      <div style={{ color: 'red', cursor: 'pointer', ...style }}>
        <Tooltip
          placement="top"
          title={`This lot has greater than ${MAX_UNDERESTIMATED_FACTOR}x or equal difference between estimate and price sold.`}
        >
          {value}
        </Tooltip>
      </div>
    );
  }

  return <div style={style}>{value}</div>;
};

const renderAuctionNoteCell = record =>
  [
    record.clean_first && 'Clean First',
    record.use_apr !== null && (record.use_apr ? 'Use APR' : 'Do Not Use APR'),
    record.custom_note,
  ]
    .filter(v => v)
    .join(', ');

const getArtistInfo = artist => {
  if (!artist) {
    return null;
  }

  let statusColor = '';

  switch (artist.artist_status) {
    case ARTIST_STATUS.PUBLISHED:
      statusColor = 'green';
      break;
    case ARTIST_STATUS.PENDING:
      statusColor = 'orange';
      break;
    default:
      statusColor = '';
  }

  return (
    <React.Fragment>
      <p>
        <b>Status: </b>
        <span style={{ color: statusColor }}>{artist.artist_status}</span>
      </p>
      <p>
        <b>Nationality: </b>
        {artist.nationality}
      </p>
      <p>
        <b>Birth: </b>
        {artist.birth}
      </p>
      {artist.death && (
        <p>
          <b>Death: </b>
          {artist.death}
        </p>
      )}
      <p>
        <b>Lots Count: </b>
        {artist.lots_count}
      </p>
    </React.Fragment>
  );
};

export const adminCleaningUiColumns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    fixed: 'left',
    render: value => (
      <a rel="noopener noreferrer" href={`/cleaning-lots/cleaning-ui?rawAuctionId=${value}`}>
        {value}
      </a>
    ),
    width: 100,
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Auction House Name',
    key: 'parsed_lot_auction_house_name',
    dataIndex: 'auction_house_name',
    width: 100,
  },
  {
    title: 'Auction Start Date',
    key: 'date',
    dataIndex: 'date',
    sorter: true,
    defaultSortOrder: 'ascend',
    width: 100,
  },
  {
    title: 'Auction End Date',
    key: 'date_end',
    dataIndex: 'date_end',
    sorter: true,
    defaultSortOrder: 'ascend',
    width: 100,
  },
  {
    title: 'Auction Number',
    key: 'number',
    dataIndex: 'number',
    width: 100,
  },
  {
    title: 'Auction Name',
    key: 'parsed_lot_auction_name',
    dataIndex: 'name',
    sorter: true,
    defaultSortOrder: 'ascend',
    width: 230,
  },
  {
    title: 'Cleaner',
    key: 'cleaner_id',
    dataIndex: 'cleaner',
    render: cleaner => (cleaner ? `${cleaner.name} ${cleaner.surname}` : 'Not assigned'),
    isHideForCleaner: true,
    width: 120,
  },
  {
    title: 'Priority',
    key: 'priority',
    dataIndex: 'priority',
    render: value => AuctionPriorityLabels[value],
    sorter: true,
    defaultSortOrder: 'ascend',
    width: 100,
  },
  {
    title: 'Notes',
    key: 'auctionNotes',
    dataIndex: 'auctionNotes',
    render: (value, record) => renderAuctionNoteCell(record),
    width: 70,
  },
  {
    title: 'Flags',
    key: 'auction_flags',
    dataIndex: 'auction_flags',
    render: value => <div dangerouslySetInnerHTML={{ __html: value }} />,
    // render: value => <div style={{ color: 'red' }}>{value}</div>,
    width: 80,
  },
  {
    title: 'Auction Status',
    key: 'auction_status',
    dataIndex: 'auction_status',
    render: value => AuctionStatusLabels[value],
    sorter: true,
    defaultSortOrder: 'ascend',
    width: 100,
  },
  {
    title: '# of lots (manual)',
    key: 'nb_of_lots_manual',
    dataIndex: 'nb_of_lots_manual',
    width: 50,
  },
  {
    title: '# of lots',
    key: 'nbParsedLots',
    dataIndex: 'nbParsedLots',
    width: 50,
  },
  {
    title: '# of lots require review',
    key: 'requireReviewCount',
    dataIndex: 'requireReviewCount',
    width: 50,
  },
  {
    title: '# of lots in review',
    key: 'nbParsedLotsInReview',
    dataIndex: 'nbParsedLotsInReview',
    width: 50,
  },
  {
    title: '# of lots published',
    key: 'nbPublishedLots',
    dataIndex: 'nbPublishedLots',
    width: 50,
  },
  {
    title: '# of lots ignored',
    key: 'nbIgnoredLots',
    dataIndex: 'nbIgnoredLots',
    width: 50,
  },
  {
    title: 'Prices Updated',
    key: 'prices_updated',
    dataIndex: 'prices_updated',
    render: value => (value ? 'Yes' : 'No'),
    width: 50,
  },
  {
    title: 'Auction Location',
    key: 'location',
    dataIndex: 'location',
    width: 100,
  },
  {
    title: 'Auction Created At',
    key: 'created_at',
    dataIndex: 'created_at',
    render: value => formatDate(value),
    width: 100,
  },
  {
    title: 'Lots Created At',
    key: 'lotDates',
    dataIndex: 'lotDates',
    render: value => value.join('\n'),
    width: 100,
  },
  {
    title: 'Price Unavailable',
    key: 'price_unavailable',
    dataIndex: 'price_unavailable',
    render: value => (value === true ? 'Yes' : 'No'),
    width: 100,
  },
];

export const getCleaningUiColumns = (
  { reviewRequiredReasonsFilter = [], currenciesFilter = [] } = {
    reviewRequiredReasonsFilter: [],
    currenciesFilter: [],
  },
) => {
  const columns = [
    {
      title: <div style={{ width: 'calc(150px - 46px)' }}>Lot ID</div>,
      key: 'lot_num',
      dataIndex: 'lot_num',
      sorter: true,
      defaultSortOrder: 'ascend',
      fixed: 'left',
      width: 150,
      render: value => <div style={{ width: 'calc(150px - 32px)' }}>{value}</div>,
      required: true,
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Lot Image Url</div>,
      key: 'lot_image_url',
      dataIndex: 'lot_image_url',
      fixed: 'left',
      width: 150,
      render: value => (
        <div style={{ width: 'calc(150px - 32px)', height: '50px', textAlign: 'center' }}>
          <a href={value} target="_blank" rel="noopener noreferrer">
            <img
              src={`${value}-thumb`}
              alt={value}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                if (!currentTarget.src.includes('-thumb')) return null;
                currentTarget.src = currentTarget.src.replace('-thumb', '');
              }}
              style={{ width: 'auto', height: '100%' }}
            />
          </a>
        </div>
      ),
      required: true,
    },
    {
      title: <div style={{ width: 'calc(150px - 46px)' }}>Medium Final</div>,
      key: 'medium_final',
      dataIndex: 'medium_final',
      sorter: true,
      width: 150 + 28,
      filters: mediumFinals.map(m => ({
        text: m[1],
        value: m[0],
      })),
      render: value => (
        <div style={{ width: 'calc(170px - 32px)' }}>{mediumFinalsMapping[value]}</div>
      ),
    },
    {
      title: <div style={{ width: 'calc(332px - 32px)' }}>Artwork Materials</div>,
      key: 'artwork_materials',
      dataIndex: 'artwork_materials',
      width: 332,
      render: value => <div style={{ width: 'calc(332px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(200px - 46px)' }}>Cleaner Review Status</div>,
      key: 'cleaner_review_status',
      dataIndex: 'cleaner_review_status',
      sorter: true,
      width: 200 + 28,
      filters: (() => {
        const arr = cleanerReviewStatuses.map(s => ({
          text: s,
          value: s,
        }));
        arr.push({
          text: 'none',
          value: 'None',
        });
        return arr;
      })(),
      render: value => <div style={{ width: 'calc(200px - 32px)' }}>{value || 'None'}</div>,
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Requires Review?</div>,
      key: 'review_required',
      dataIndex: 'review_required',
      sorter: true,
      width: 150 + 28,
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      render: (value, row) => (
        <div style={{ width: 'calc(150px - 32px)' }}>
          {value ? (row.artist && row.artist.is_hidden ? 'No' : 'Yes') : 'No'}
        </div>
      ),
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Lot Withdrawn</div>,
      key: 'lot_withdrawn',
      dataIndex: 'lot_withdrawn',
      sorter: true,
      width: 150 + 28,
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      render: (value, row) => (
        <div style={{ width: 'calc(150px - 32px)' }}>
          {value ? (row.lot_withdrawn ? 'Yes' : 'No') : 'No'}
        </div>
      ),
    },
    {
      title: <div style={{ width: 'calc(250px - 32px)' }}>Reason</div>,
      key: 'review_required_reason',
      dataIndex: 'review_required_reason',
      sorter: true,
      width: 250 + 28,
      filters: reviewRequiredReasonsFilter,
      render: (value, row) => {
        let multipleArtistsLinks;
        if (value && value.includes('found') && value.includes('artists')) {
          const multipleArtistsIds = value
            .split(',\n')
            .find(v => v.includes('found') && v.includes('artists'))
            .split(': ')[1]
            .split(',');

          multipleArtistsLinks = multipleArtistsIds.map(id => (
            <a
              style={{ marginRight: '5px' }}
              href={`https://artmetadata.io/artists/${id}/artist`}
              target="_blank"
            >
              {id}
            </a>
          ));
        }
        return (
          <div style={{ width: 'calc(250px - 32px)', cursor: 'pointer' }}>
            <Tooltip placement="topLeft" title={value}>
              {row.artist && row.artist.is_hidden ? 'Hidden Artist' : value}
            </Tooltip>
            {multipleArtistsLinks && <div>Multiple Artists: {multipleArtistsLinks}</div>}
          </div>
        );
      },
    },
    {
      title: <div style={{ width: 'calc(200px - 32px)' }}>Artist ID</div>,
      key: 'artist_id',
      dataIndex: 'artist_id',
      width: 200,
      render: (value, model) => {
        if (isNil(value)) {
          return null;
        }
        return (
          <Popover
            title={model.artist ? model.artist.name : ''}
            content={getArtistInfo(model.artist)}
          >
            <a
              href={getAuthorUrl(value)}
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: 'calc(200px - 32px)' }}
            >
              {value}
            </a>
            <br />
            {model.artist && model.artist.name}
          </Popover>
        );
      },
    },
    {
      title: <div style={{ width: 'calc(200px - 32px)' }}>Classified Artist ID</div>,
      key: 'classified_artist_id',
      dataIndex: 'classified_artist_id',
      width: 200,
      render: (value, model) => {
        if (isNil(value)) {
          return null;
        }
        return (
          <Popover
            title={model.classified_artist ? model.classified_artist.name : ''}
            content={getArtistInfo(model.classified_artist)}
          >
            <a
              href={getAuthorUrl(value)}
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: 'calc(200px - 32px)' }}
            >
              {value}
            </a>
            <br />
            {model.classified_artist && model.classified_artist.name}
          </Popover>
        );
      },
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Classified Artist Status</div>,
      key: 'classified_artist_status',
      dataIndex: 'classified_artist_status',
      width: 150,
      render: (value, model) => {
        const artist = model.classified_artist;
        if (isNil(artist)) {
          return null;
        }
        return (
          <div
            style={{
              width: 'calc(150px - 32px)',
              color: artist.artist_status === ARTIST_STATUS.PENDING ? 'orange' : '',
            }}
          >
            {artist.artist_status}
          </div>
        );
      },
    },
    {
      title: <div style={{ width: 'calc(150px - 46px)' }}>Artist Qualifier</div>,
      key: 'artist_qualifier',
      dataIndex: 'artist_qualifier',
      sorter: true,
      width: 150,
      render: value => <div style={{ width: 'calc(150px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(300px - 32px)' }}>Artwork Name</div>,
      key: 'artwork_name',
      dataIndex: 'artwork_name',
      width: 300,
      render: value => <div style={{ width: 'calc(300px - 32px)' }}>{value}</div>,
      required: true,
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Creation Year</div>,
      key: 'artwork_creation_year',
      dataIndex: 'artwork_creation_year',
      width: 150,
      render: value => <div style={{ width: 'calc(150px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(300px - 32px)' }}>Artwork Marking</div>,
      key: 'artwork_markings',
      dataIndex: 'artwork_markings',
      width: 300,
      render: value => <div style={{ width: 'calc(300px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(120px - 32px)' }}>Edition Size</div>,
      key: 'artwork_edition_size',
      dataIndex: 'artwork_edition_size',
      width: 120,
      render: value => <div style={{ width: 'calc(120px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(135px - 32px)' }}>Edition Current</div>,
      key: 'artwork_edition_current',
      dataIndex: 'artwork_edition_current',
      width: 125,
      render: value => <div style={{ width: 'calc(125px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(120px - 32px)' }}>Estimate Min</div>,
      key: 'price_estimate_min',
      dataIndex: 'price_estimate_min',
      sorter: true,
      defaultSortOrder: 'ascend',
      width: 120,
      render: renderPriceEstimateColumn({ width: 'calc(120px - 32px)' }),
    },
    {
      title: <div style={{ width: 'calc(120px - 32px)' }}>Estimate Max</div>,
      key: 'price_estimate_max',
      sorter: true,
      // defaultSortOrder: 'ascend',
      dataIndex: 'price_estimate_max',
      width: 120,
      render: renderPriceEstimateColumn({ width: 'calc(120px - 32px)' }),
    },
    {
      title: <div style={{ width: 'calc(110px - 32px)' }}>Price Sold</div>,
      key: 'price_sold',
      sorter: true,
      // defaultSortOrder: 'ascend',
      dataIndex: 'price_sold',
      width: 110,
      render: renderPriceEstimateColumn({ width: 'calc(110px - 32px)' }),
    },
    {
      title: <div style={{ width: 'calc(120px - 32px)' }}>Price Kind</div>,
      key: 'price_kind',
      dataIndex: 'price_kind',
      width: 120,
      render: value => <div style={{ width: 'calc(120px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(120px - 32px)' }}>Guaranteed</div>,
      key: 'financial_guarantee',
      dataIndex: 'financial_guarantee',
      width: 100 + 28,
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      render: value => <div style={{ width: 'calc(120px - 32px)' }}>{value ? 'Yes' : 'No'}</div>,
    },
    {
      title: <div style={{ width: 'calc(100px - 32px)' }}>Currency</div>,
      key: 'currency',
      dataIndex: 'currency',
      width: 100 + 28,
      filters: currenciesFilter,
      render: value => <div style={{ width: 'calc(100px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(268px - 32px)' }}>Artwork Condition</div>,
      key: 'artwork_condition_in',
      dataIndex: 'artwork_condition_in',
      width: 268,
      render: value => <div style={{ width: 'calc(268px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(125px - 32px)' }}>Artwork Width</div>,
      key: 'artwork_measurements_width',
      dataIndex: 'artwork_measurements_width',
      width: 125,
      render: value => <div style={{ width: 'calc(125px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(130px - 32px)' }}>Artwork Height</div>,
      key: 'artwork_measurements_height',
      dataIndex: 'artwork_measurements_height',
      width: 130,
      render: value => <div style={{ width: 'calc(130px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(125px - 32px)' }}>Artwork Depth</div>,
      key: 'artwork_measurements_depth',
      dataIndex: 'artwork_measurements_depth',
      width: 125,
      render: value => <div style={{ width: 'calc(125px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(125px - 32px)' }}>Artwork Unit</div>,
      key: 'artwork_measurements_unit',
      dataIndex: 'artwork_measurements_unit',
      width: 125,
      render: value => <div style={{ width: 'calc(125px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(332px - 32px)' }}>Artwork Size Notes</div>,
      key: 'artwork_size_notes',
      dataIndex: 'artwork_size_notes',
      width: 332,
      render: value => <div style={{ width: 'calc(332px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(450px - 32px)' }}>Artwork Provenance</div>,
      key: 'artwork_provenance',
      dataIndex: 'artwork_provenance',
      width: 450,
      render: value => <div style={{ width: 'calc(450px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(247px - 32px)' }}>Artwork Exhibited</div>,
      key: 'artwork_exhibited',
      dataIndex: 'artwork_exhibited',
      width: 247,
      render: value => <div style={{ width: 'calc(247px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(432px - 32px)' }}>Artwork Literature</div>,
      key: 'artwork_literature',
      dataIndex: 'artwork_literature',
      width: 432,
      render: value => <div style={{ width: 'calc(432px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(251px - 32px)' }}>Publish Status Text</div>,
      key: 'publish_status_text',
      dataIndex: 'publish_status_text',
      width: 251,
      render: value => (
        <div style={{ width: 'calc(251px - 32px)', cursor: 'pointer' }}>
          <Tooltip placement="left" title={value}>
            {value}
          </Tooltip>
        </div>
      ),
    },
    {
      title: <div style={{ width: 'calc(450px - 32px)' }}>Artwork Description</div>,
      key: 'artwork_description',
      dataIndex: 'artwork_description',
      width: 450,
      render: value => <div style={{ width: 'calc(450px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(304px - 32px)' }}>Artwork Creation Notes</div>,
      key: 'creation_notes',
      dataIndex: 'creation_notes',
      width: 304,
      render: value => <div style={{ width: 'calc(304px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(450px - 32px)' }}>Lot Origin Url</div>,
      key: 'lot_origin_url',
      dataIndex: 'lot_origin_url',
      width: 450,
      render: value => (
        <div style={{ width: 'calc(450px - 32px)' }}>
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        </div>
      ),
      required: true,
    },
    {
      title: <div style={{ width: 'calc(180px - 32px)' }}>Auction House Name</div>,
      key: 'auction_house_name',
      dataIndex: 'auction_house_name',
      width: 180,
      render: value => <div style={{ width: 'calc(180px - 32px)' }}>{value}</div>,
      required: true,
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Auction House ID</div>,
      key: 'auction_house_id',
      dataIndex: 'auction_house_id',
      width: 150,
      render: value => <div style={{ width: 'calc(150px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(250px - 32px)' }}>Auction Name</div>,
      key: 'auction_name',
      dataIndex: 'auction_name',
      width: 250,
      render: value => <div style={{ width: 'calc(250px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(250px - 32px)' }}>Auction ID</div>,
      key: 'raw_auction_id',
      dataIndex: 'raw_auction_id',
      width: 250,
      render: value => (
        <div style={{ width: 'calc(250px - 32px)' }}>
          <a
            href={`/cleaning-lots/cleaning-ui?rawAuctionId=${value}`}
            target="_blank"
            rel="noreferrer"
          >
            {value}
          </a>
        </div>
      ),
    },
    {
      title: <div style={{ width: 'calc(200px - 32px)' }}>Auction Location</div>,
      key: 'auction_location',
      dataIndex: 'auction_location',
      width: 200,
      render: value => <div style={{ width: 'calc(200px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Auction Number</div>,
      key: 'auction_num',
      dataIndex: 'auction_num',
      width: 150,
      render: value => <div style={{ width: 'calc(150px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Auction Start Date</div>,
      key: 'auction_start_date',
      dataIndex: 'auction_start_date',
      width: 150,
      render: value => <div style={{ width: 'calc(150px - 32px)' }}>{formatDate(value)}</div>,
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Auction End Date</div>,
      key: 'auction_end_date',
      dataIndex: 'auction_end_date',
      width: 150,
      render: value => <div style={{ width: 'calc(150px - 32px)' }}>{formatDate(value)}</div>,
    },
    {
      title: <div style={{ width: 'calc(120px - 32px)' }}>Raw Lot ID</div>,
      key: 'raw_lot_id',
      dataIndex: 'raw_lot_id',
      width: 120,
      render: value => <div style={{ width: 'calc(120px - 32px)' }}>{value}</div>,
      required: true,
    },
    {
      title: <div style={{ width: 'calc(150px - 32px)' }}>Cleaner</div>,
      key: 'cleaner',
      dataIndex: 'cleaner',
      width: 150,
      render: cleaner => (
        <div style={{ width: 'calc(150px - 32px)' }}>
          {cleaner ? `${cleaner.name} ${cleaner.surname}` : 'Not assigned'}
        </div>
      ),
    },
    {
      title: <div style={{ width: 'calc(150px - 46px)' }}>Publish Status</div>,
      key: 'publish_status',
      dataIndex: 'publish_status',
      sorter: true,
      width: 150 + 28,
      filters: publishStatuses.map(s => ({
        text: s,
        value: s,
      })),
      render: value => <div style={{ width: 'calc(150px - 32px)' }}>{value}</div>,
    },
    {
      title: <div style={{ width: 'calc(140px - 46px)' }}>Review Status</div>,
      key: 'review_status',
      dataIndex: 'review_status',
      sorter: true,
      width: 140 + 28,
      filters: reviewStatuses.map(s => ({
        text: s,
        value: s,
      })),
      render: value => <div style={{ width: 'calc(140px - 32px)' }}>{value || 'None'}</div>,
    },
    {
      title: 'Notes',
      key: 'auctionNotes',
      dataIndex: 'auctionNotes',
      render: (value, record) => renderAuctionNoteCell(record),
      width: 195,
    },
  ];

  return columns;
};
