import './index.scss';

import { Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { Request } from 'shared/tools/request';
import birthDeathTypes from '../../../../../src/utils/birth-death-types';

const { TextArea } = Input;
let timeOut = null;

class MergeArtistModal extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    createArtist: PropTypes.func.isRequired,
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    artistQualifiers: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      artistsInput1: null,
      artistsInput2: null,
      artists: [],
      artists1: [],
      artist: {},
      artist1: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  setArtist(artist, num) {
    this.setState(prevState => {
      const state = Object.assign({}, prevState);
      if (num === 1) {
        state.artist = artist;
        state.artists = [];
        this.props.form.setFieldsValue({ artist_name1: '' });
      }
      if (num === 2) {
        state.artist1 = artist;
        state.artists1 = [];
        this.props.form.setFieldsValue({ artist_name2: '' });
      }
      return state;
    });
  }

  onOk = () => {
    const {
      form: { validateFieldsAndScroll },
      createArtist,
    } = this.props;

    validateFieldsAndScroll((err, fields) => {
      if (!err) {
        createArtist(fields);
      }
    });
  };

  async handleChange(ev, num) {
    const { value } = ev.target;
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(async () => this.doRequest(value, num), 350);
  }

  async doRequest(name, num) {
    let data = false;
    if (name && name.trim() !== '') {
      const response = await Request.post('/api/artist', {
        data: { name, limit: 5, es: true, dbOutput: true },
      });
      data = response.data;
    }
    if (data && data.rows.length > 0) {
      this.setState(prevState => {
        const state = Object.assign({}, prevState);
        state[num === 1 ? 'artists' : 'artists1'] = data.rows;
        return state;
      });
    } else {
      this.setState(prevState => {
        const state = Object.assign({}, prevState);
        state[num === 1 ? 'artists' : 'artists1'] = [];
        return state;
      });
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
      visible,
      closeModal,
      isLoading,
    } = this.props;

    return (
      <Modal
        title="Merge Artists"
        visible={visible}
        onCancel={closeModal}
        okButtonProps={{
          disabled: !(this.state.artist1.id && this.state.artist.id) || isLoading,
          onClick: this.onOk,
        }}
        width="60vw"
        okText="Merge"
        bodyStyle={{
          height: '70vh',
          overflowY: 'scroll',
        }}
      >
        {isLoading ? (
          <div className="edit-artist__spin">
            <Spin />
          </div>
        ) : (
          <Form onSubmit={this.onSubmit}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Search Artist (Final artist)">
                  {getFieldDecorator('artist_name1', {
                    initialValue: this.state.artistsInput1 ? this.state.artistsInput1 : '',
                  })(<Input onChange={(ev) => this.handleChange(ev, 1)} placeholder="Search by name" />)}
                  {this.state.artists.length > 0 && (
                    <div className="art-autosuggest">
                      {this.state.artists.map(artist => (
                        <div
                          key={artist.id}
                          className="art-autosuggest-item"
                          onClick={() => this.setArtist(artist, 1)}
                        >
                          {'#'}{artist.id}{' '}{artist.name}
                        </div>
                      ))}
                    </div>
                  )}
                </Form.Item>
                {getFieldDecorator('final_id', {
                  initialValue: this.state.artist.id ? this.state.artist.id : null,
                  rules: [
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ],
                })(<Input hidden />)}
                <Form.Item label="Final ID">
                  {getFieldDecorator('final_id', {
                    initialValue: this.state.artist.id ? this.state.artist.id : null,
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                  })(<Input disabled />)}
                </Form.Item>
                <Form.Item label="Final Name">
                  {getFieldDecorator('final_name', {
                    initialValue: this.state.artist.name ? this.state.artist.name : null,
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="Final Nationality">
                  {getFieldDecorator('final_nationality', {
                    initialValue: this.state.artist.nationality
                      ? this.state.artist.nationality
                      : null,
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="Final Birth">
                  {getFieldDecorator('final_birth', {
                    initialValue: this.state.artist.birth ? this.state.artist.birth : null,
                  })(<Input type="number" />)}
                </Form.Item>
                <Form.Item label="Final Death">
                  {getFieldDecorator('final_death', {
                    initialValue: this.state.artist.death ? this.state.artist.death : null,
                  })(<Input type="number" />)}
                </Form.Item>
                <Form.Item label="Birth/Death type">
                  {getFieldDecorator('final_birth_death_type', {
                    initialValue: this.state.artist.birth_death_type
                      ? this.state.artist.birth_death_type
                      : null,
                  })(
                    <Select>
                      {Object.keys(birthDeathTypes).map(value => (
                        <Select.Option key={value} value={value}>
                          {birthDeathTypes[value]}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Final Alternate Spellings">
                  {getFieldDecorator('final_alternate_spellings', {
                    initialValue: this.state.artist.alternate_spellings
                      ? this.state.artist.alternate_spellings
                      : null,
                  })(<TextArea autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
                <Form.Item label="Final Qualifier">
                  {getFieldDecorator('final_qualifier', {
                    initialValue: this.state.artist.qualifier ? this.state.artist.qualifier : null,
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="Final Active Date">
                  {getFieldDecorator('final_active_date', {
                    initialValue: this.state.artist.active_date
                      ? this.state.artist.active_date
                      : null,
                  })(<Input maxLength={120} />)}
                </Form.Item>
                <Form.Item label="Final Qualified Artist Id">
                  {getFieldDecorator('final_qualified_artist_id', {
                    initialValue: this.state.artist.qualified_artist_id
                      ? this.state.artist.qualified_artist_id
                      : null,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Search Artist (Merge from)">
                  {getFieldDecorator('artist_name2', {
                    initialValue: this.state.artistsInput2 ? this.state.artistsInput2 : '',
                  })(<Input onChange={this.handleChange} placeholder="Search by name" />)}
                  {this.state.artists1.length > 0 && (
                    <div className="art-autosuggest">
                      {this.state.artists1.map(artist => (
                        <div
                          key={artist.id}
                          className="art-autosuggest-item"
                          onClick={() => this.setArtist(artist, 2)}
                        >
                          {'#'}{artist.id}{' '}{artist.name}
                        </div>
                      ))}
                    </div>
                  )}
                </Form.Item>
                {getFieldDecorator('id_to_merge', {
                  initialValue: this.state.artist1.id ? this.state.artist1.id : null,
                })(<Input hidden />)}
                <Form.Item label="ID">
                  {getFieldDecorator('id', {
                    initialValue: this.state.artist1.id ? this.state.artist1.id : null,
                    rules: [
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ],
                  })(<Input disabled />)}
                </Form.Item>
                <Form.Item label="Name">
                  {getFieldDecorator('name', {
                    initialValue: this.state.artist1.name ? this.state.artist1.name : null,
                  })(<Input disabled />)}
                </Form.Item>
                <Form.Item label="Nationality">
                  {getFieldDecorator('nationality', {
                    initialValue: this.state.artist1.nationality
                      ? this.state.artist1.nationality
                      : null,
                  })(<Input disabled />)}
                </Form.Item>
                <Form.Item label="Birth / Established">
                  {getFieldDecorator('birth', {
                    initialValue: this.state.artist1.birth ? this.state.artist1.birth : null,
                  })(<Input disabled type="number" />)}
                </Form.Item>
                <Form.Item label="Death">
                  {getFieldDecorator('death', {
                    initialValue: this.state.artist1.death ? this.state.artist1.death : null,
                  })(<Input disabled type="number" />)}
                </Form.Item>
                <Form.Item label="Birth/Death type">
                  {getFieldDecorator('birth_death_type', {
                    initialValue: this.state.artist1.birth_death_type
                      ? this.state.artist1.birth_death_type
                      : null,
                  })(
                    <Select disabled>
                      {Object.keys(birthDeathTypes).map(value => (
                        <Select.Option key={value} value={value}>
                          {birthDeathTypes[value]}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Alternate Spellings">
                  {getFieldDecorator('alternate_spellings', {
                    initialValue: this.state.artist1.alternate_spellings
                      ? this.state.artist1.alternate_spellings
                      : null,
                  })(<TextArea disabled autosize={{ minRows: 1, maxRows: 4 }} />)}
                </Form.Item>
                <Form.Item label="Qualifier">
                  {getFieldDecorator('Qualifier', {
                    initialValue: this.state.artist1.qualifier
                      ? this.state.artist1.qualifier
                      : null,
                  })(<Input disabled />)}
                </Form.Item>
                <Form.Item label="Active Date">
                  {getFieldDecorator('active_date', {
                    initialValue: this.state.artist1.active_date
                      ? this.state.artist1.active_date
                      : null,
                  })(<Input disabled />)}
                </Form.Item>
                <Form.Item label="Qualified Artist Id">
                  {getFieldDecorator('qualified_artist_id', {
                    initialValue: this.state.artist1.qualified_artist_id
                      ? this.state.artist1.qualified_artist_id
                      : null,
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  }
}

export default MergeArtistModal;
