import './index.scss';

import { ARTIST_STATUS, MAX_UNDERESTIMATED_FACTOR } from 'shared/constants/app';
import { AuctionNotesTypes, AuctionPriorityLabels } from 'cleaning-lots/constants/raw-auction';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';
import React, { PureComponent } from 'react';
import {
  currencies,
  measurements,
} from 'cleaning-lots/constants/lot-fields';

import PropTypes from 'prop-types';
import validationErrors from 'shared/tools/validation-errors';
import aprFilters from 'shared/tools/apr-filters';

const { Panel } = Collapse;
const { TextArea } = Input;

const dateFormat = 'YYYY-MM-DD';

export class Search extends PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
      resetFields: PropTypes.func.isRequired,
    }).isRequired,
    cleaners: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        surname: PropTypes.string,
      }),
    ).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    activeAuctionId: PropTypes.number,
    rawAuction: PropTypes.object,

    findLots: PropTypes.func.isRequired,
    removeAPR: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeAuctionId: null,
    rawAuction: null,
  };

  constructor(props) {
    super(props);
    this.state = { isOpened: false, rmAPRopened: false };
  }

  onRemoveAPR = e => {
    e.preventDefault();

    const {
      form: { validateFieldsAndScroll },
    } = this.props;

    validateFieldsAndScroll((err, fields) => {
      this.props.removeAPR({
        raw_auction_id: fields.raw_auction_id,
        ...fields.rmAPR,
      });
    });
  };

  onSearch = e => {
    e.preventDefault();

    const {
      form: { validateFieldsAndScroll },
    } = this.props;

    validateFieldsAndScroll((err, fields) => {
      if (!err) {
        const [clean_first, use_apr] = AuctionNotesTypes[fields.auction_note];

        delete fields.auction_note;
        fields.auction_clean_first = clean_first;
        fields.auction_use_apr = use_apr;

        Object.keys(fields).forEach(key => {
          if (fields[key] === '') {
            delete fields[key];
          }
        });

        this.props.findLots(fields);
      }
    });
  };

  onClear = () => {
    this.props.findLots();
    this.props.form.resetFields();
  };

  render() {
    const {
      form: { getFieldDecorator, isFieldsTouched },
      cleaners,
      isAdmin,
      activeAuctionId,
      rawAuction,
    } = this.props;

    let ignores = false;
    if (activeAuctionId && rawAuction) ignores = rawAuction.ignore_apr;
    const isButtonsDisabled = !isFieldsTouched();

    const underestimatedCheckbox = (
      <Tooltip
        placement="top"
        title={`Lots which have greater than ${MAX_UNDERESTIMATED_FACTOR}x or equal difference between estimate and price sold.`}
      >
        {`${MAX_UNDERESTIMATED_FACTOR}x Price / Estimate Outliers`}
      </Tooltip>
    );

    const hiddenLotsCheckbox = (
      <Tooltip placement="top" title="Show lots imported from original.csv">
        Show hidden lots
      </Tooltip>
    );

    const lpIgnoredHighPriceSoldCheckbox = (
      <Tooltip placement="top" title="Show LP ignored lots with price sold > 1000 GBP">
        Show LP ignored lots with high price sold
      </Tooltip>
    )

    return (
      <div className="cleaning-search">
        <Collapse>
          <Panel header="Search" key="1">
            <Form onSubmit={this.onSearch}>
              <Row gutter={24}>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Auction Priority">
                    {getFieldDecorator('auction_priority', {
                      initialValue: null,
                    })(
                      <Select>
                        <Select.Option value={null}>None</Select.Option>
                        {Object.entries(AuctionPriorityLabels).map(([key, value]) => (
                          <Select.Option key={key} value={key}>
                            {value}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Auction Notes">
                    {getFieldDecorator('auction_note', {
                      initialValue: 0,
                    })(
                      <Select>
                        {AuctionNotesTypes.map(([clean_first, use_apr, label], i) => (
                          <Select.Option key={label} value={i}>
                            {label}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Raw Lot ID">
                    {getFieldDecorator('raw_lot_id', {
                      initialValue: null,
                      rules: [
                        {
                          pattern: /^[0-9]*(,\s*[0-9]*)*$/,
                          message: "Raw Lot ID's should be separated by comma",
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                {isAdmin && (
                  <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item label="Cleaner">
                      {getFieldDecorator('cleaner_id', {
                        initialValue: null,
                      })(
                        <Select>
                          <Select.Option value={null}>None</Select.Option>
                          {cleaners.map(cleaner => (
                            <Select.Option key={cleaner.id} value={cleaner.id}>
                              {cleaner.name}

                              {cleaner.surname}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                )}

                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Auction House Name">
                    {getFieldDecorator('auction_house_name', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>

                {!activeAuctionId && (
                  <React.Fragment>
                    <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                      <Form.Item label="Auction ID">
                        {getFieldDecorator('raw_auction_id', {
                          initialValue: null,
                          rules: [
                            {
                              pattern: /^[0-9]*(,\s*[0-9]*)*$/,
                              message: "Raw Lot ID's should be separated by comma",
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                      <Form.Item label="Auction Location">
                        {getFieldDecorator('auction_location', {
                          initialValue: null,
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                      <Form.Item label="Auction Name">
                        {getFieldDecorator('auction_name', {
                          initialValue: null,
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                      <Form.Item label="Auction Number">
                        {getFieldDecorator('auction_num', {
                          initialValue: null,
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                      <Form.Item label="Auction Start Date">
                        {getFieldDecorator('auction_start_date', {
                          initialValue: null,
                        })(<DatePicker format={dateFormat} />)}
                      </Form.Item>
                    </Col>
                    <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                      <Form.Item label="Auction End Date">
                        {getFieldDecorator('auction_end_date', {
                          initialValue: null,
                        })(<DatePicker format={dateFormat} />)}
                      </Form.Item>
                    </Col>
                    <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                      <Form.Item label="Lot Origin Url">
                        {getFieldDecorator('lot_origin_url', {
                          initialValue: null,
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </React.Fragment>
                )}
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Lot Image Url">
                    {getFieldDecorator('lot_image_url', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 6 }} xs={{ span: 12 }}>
                  <Form.Item label="Lot Number">
                    {getFieldDecorator('lot_num', {
                      initialValue: null,
                    })(<InputNumber style={{ width: '50%' }} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
                  <Form.Item label="Minimum Low Estimate">
                    {getFieldDecorator('price_estimate_min_gte', {
                      initialValue: null,
                    })(<InputNumber style={{ width: '50%' }} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
                  <Form.Item label="Minimum High Estimate">
                    {getFieldDecorator('price_estimate_max_gte', {
                      initialValue: null,
                    })(<InputNumber style={{ width: '50%' }} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
                  <Form.Item label="Min. Price Sold">
                    {getFieldDecorator('price_sold_gte', {
                      initialValue: null,
                    })(<InputNumber style={{ width: '50%' }} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 3 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Price Kind">
                    {getFieldDecorator('price_kind', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
                  <Form.Item label="Lot Withdrawn">
                    {getFieldDecorator('lot_withdrawn')(
                      <Select>
                        <Select.Option key="gYes" value>
                          Yes
                        </Select.Option>
                        <Select.Option key="gNo" value={false}>
                          No
                        </Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Аrtwork Сondition In">
                    {getFieldDecorator('artwork_condition_in', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Artwork Creation Year">
                    {getFieldDecorator('artwork_creation_year', {
                      initialValue: null,
                    })(<InputNumber />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Artwork Creation Notes">
                    {getFieldDecorator('creation_notes', {
                      initialValue: null,
                    })(<Input maxLength={120} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Description">
                    {getFieldDecorator('artwork_description', {
                      initialValue: null,
                    })(<TextArea autoSize={{ minRows: 1, maxRows: 4 }} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Edition Current">
                    {getFieldDecorator('artwork_edition_current', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Edition Size">
                    {getFieldDecorator('artwork_edition_size', {
                      initialValue: null,
                    })(<InputNumber />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Exhibited">
                    {getFieldDecorator('artwork_exhibited', {
                      initialValue: null,
                    })(<TextArea autoSize={{ minRows: 1, maxRows: 4 }} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Literature">
                    {getFieldDecorator('artwork_literature', {
                      initialValue: null,
                    })(<TextArea autoSize={{ minRows: 1, maxRows: 4 }} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Markings">
                    {getFieldDecorator('artwork_markings', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Materials">
                    {getFieldDecorator('artwork_materials', {
                      initialValue: null,
                    })(<TextArea autoSize={{ minRows: 1, maxRows: 4 }} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Artwork Measurements Width">
                    {getFieldDecorator('artwork_measurements_width', {
                      initialValue: null,
                    })(<InputNumber />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Artwork Measurements Height">
                    {getFieldDecorator('artwork_measurements_height', {
                      initialValue: null,
                    })(<InputNumber />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Artwork Measurements Depth">
                    {getFieldDecorator('artwork_measurements_depth', {
                      initialValue: null,
                    })(<InputNumber />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item label="Artwork Measurements Unit">
                    {getFieldDecorator('artwork_measurements_unit', {
                      initialValue: null,
                    })(
                      <Select>
                        <Select.Option value={null}>None</Select.Option>
                        {measurements.map(measurement => (
                          <Select.Option key={measurement} value={measurement}>
                            {measurement}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Name">
                    {getFieldDecorator('artwork_name', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Provenance">
                    {getFieldDecorator('artwork_provenance', {
                      initialValue: null,
                    })(<TextArea autoSize={{ minRows: 1, maxRows: 4 }} />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artwork Size Notes">
                    {getFieldDecorator('artwork_size_notes', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artist ID">
                    {getFieldDecorator('artist_id', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Classified Artist ID">
                    {getFieldDecorator('classified_artist_id', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artist Qualifier">
                    {getFieldDecorator('artist_qualifier', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Artist Status">
                    {getFieldDecorator('artist_status', {
                      initialValue: null,
                    })(
                      <Select>
                        <Select.Option value={null}>all</Select.Option>
                        {Object.values(ARTIST_STATUS).map(status => (
                          <Select.Option key={status} value={status}>
                            {status}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label="Publish Status text">
                    {getFieldDecorator('publish_status_text', {
                      initialValue: null,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label={underestimatedCheckbox}>
                    {getFieldDecorator('is_show_only_underestimated', {
                      initialValue: false,
                      valuePropName: 'checked',
                    })(<Checkbox>Show</Checkbox>)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label={hiddenLotsCheckbox}>
                    {getFieldDecorator('show_broken_lots', {
                      initialValue: false,
                      valuePropName: 'checked',
                    })(<Checkbox>Show Hidden Lots</Checkbox>)}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
                  <Form.Item label={lpIgnoredHighPriceSoldCheckbox}>
                    {getFieldDecorator('show_lp_ignored_lots_high_price_sold', {
                      initialValue: false,
                      valuePropName: 'checked',
                    })(<Checkbox>Show LP Ignored lots</Checkbox>)}
                  </Form.Item>
                </Col>
                {/* <Col xl={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}> */}
                {/*  <Form.Item label="Only Priced Above"> */}
                {/*    {getFieldDecorator('price_estimate_min_gt', { */}
                {/*      initialValue: null, */}
                {/*    })(<Input />)} */}
                {/*  </Form.Item> */}
                {/* </Col> */}
              </Row>
              <Collapse
                onChange={() => {
                  this.setState(state => ({
                    isOpened: !state.isOpened,
                  }));
                }}
                style={{ marginBottom: '15px' }}
              >
                <Panel header="Recommendations filters" key="2">
                  {this.state.isOpened &&
                    Object.keys(validationErrors).map(key => (
                      <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                        <Form.Item label={validationErrors[key]} key={key}>
                          {getFieldDecorator(`errors[${key}]`, {
                            initialValue: false,
                            valuePropName: 'checked',
                          })(<Checkbox>{validationErrors[key]}</Checkbox>)}
                        </Form.Item>
                      </Col>
                    ))}
                </Panel>
              </Collapse>
              <div>
                <Button.Group>
                  <Button onClick={this.onClear} disabled={isButtonsDisabled}>
                    Clear Fields
                  </Button>
                  <Button type="primary" htmlType="submit" disabled={isButtonsDisabled}>
                    Find
                  </Button>
                </Button.Group>
              </div>
            </Form>
          </Panel>
        </Collapse>
        <Collapse
          onChange={() => {
            this.setState(state => ({
              rmAPRopened: !state.rmAPRopened,
            }));
          }}
          style={{ marginBottom: '15px' }}
        >
          <Panel header="Remove APR flags" key="3">
            <Form onSubmit={this.onRemoveAPR}>
              {this.state.rmAPRopened &&
                activeAuctionId &&
                Object.keys(aprFilters).map(key => (
                  <Col xl={{ span: 6 }} md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item label={aprFilters[key]} key={`rm-${key}`}>
                      {getFieldDecorator(`rmAPR[${key}]`, {
                        initialValue: !!(ignores && ignores[key]),
                        valuePropName: 'checked',
                      })(<Checkbox>{aprFilters[key]}</Checkbox>)}
                    </Form.Item>
                  </Col>
                ))}
              {getFieldDecorator('raw_auction_id', {
                initialValue: activeAuctionId,
              })(<Input type="hidden" />)}
              <div>
                <Button.Group>
                  <Button type="primary" htmlType="submit" disabled={isButtonsDisabled}>
                    Ignore Selected
                  </Button>
                </Button.Group>
              </div>
            </Form>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
