import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

class ReAssignModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    getLots: PropTypes.func.isRequired,
    reAssignToClean: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    auctionId: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  reAssignToClean = async () => {
    await this.props.reAssignToClean([this.props.auctionId]);
    this.props.getLots();
    this.props.closeModal();
  };

  render() {
    const title = `Re-assign to clean`;
    const question = `Are you sure you want to reassign this sale for cleaning?`;
    return (
      <Modal
        title={title}
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        onOk={this.reAssignToClean}
        okText={'Yes'}
      >
        <p>{question}</p>
      </Modal>
    );
  }
}

export { ReAssignModal };
