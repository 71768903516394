import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { setSelectedAuctions, startCleaning } from 'cleaning-lots/actions/admin-cleaning';
import { StartCleaningModal } from 'cleaning-lots/components/AdminCleaningUI/StartCleaningModal';

const stateToProps = state => ({});

const dispatchToProps = {
  startCleaning,
  setSelectedAuctions,
};

export default compose(connect(stateToProps, dispatchToProps), Form.create())(StartCleaningModal);
