import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Modal } from 'antd';

import Layout from 'main/containers/Layout';

import { ITEMS_PER_PAGE } from 'shared/constants/items-per-page';

import Search from 'artist/containers/search';
import EditArtistModal from 'artist/containers/edit-modal';
import CreateArtistModal from 'artist/containers/create-modal';
import MergeArtistModal from 'artist/containers/merge-modal';

import { ARTIST_STATUS } from 'shared/constants/app';

const orderLabels = {
  descend: 'desc',
  ascend: 'asc',
};

export default class ArtistUI extends React.PureComponent {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    getArtists: PropTypes.func.isRequired,
    loadQualifiers: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setSortDirection: PropTypes.func.isRequired,
    setSortBy: PropTypes.func.isRequired,
    setArtistToEdit: PropTypes.func.isRequired,
    artistRows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        qualifier: PropTypes.string,
        qualified_artist_id: PropTypes.number,
        url_thumb: PropTypes.string,
        lots_count: PropTypes.number,
      }),
    ).isRequired,
    count: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        dataIndex: PropTypes.string.isRequired,
        fixed: PropTypes.string,
        render: PropTypes.func,
      }),
    ).isRequired,
    sortDir: PropTypes.string.isRequired,
    sortBy: PropTypes.string.isRequired,
    artistToEdit: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      qualifier: PropTypes.string,
      qualified_artist_id: PropTypes.number,
      url_thumb: PropTypes.string,
      lots_count: PropTypes.number,
    }),
    isCreateArtistModalOpen: PropTypes.bool.isRequired,
    isMergeArtistModalOpen: PropTypes.bool.isRequired,
    openCreateArtistModal: PropTypes.func.isRequired,
    openMergeArtistModal: PropTypes.func.isRequired,
    closeCreateArtistModal: PropTypes.func.isRequired,
    closeMergeArtistModal: PropTypes.func.isRequired,
    setToInitialState: PropTypes.func.isRequired,
  };

  static defaultProps = {
    artistToEdit: null,
  };

  state = {
    isImageModalVisible: false,
    imageUrl: '',
    isEditArtistModalOpened: false,
  };

  componentDidMount() {
    this.props.loadQualifiers();
  }

  componentWillUnmount() {
    this.props.setToInitialState();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const newSortDir = orderLabels[sorter.order];
    const newSortBy = sorter.field;
    const newPage = pagination.current;
    const { sortDir, sortBy, currentPage } = this.props;

    const isSortDirChanged = newSortDir !== sortDir;
    const isSortByChanged = newSortBy !== sortBy;
    const isPageChanged = newPage !== currentPage;

    if (isSortDirChanged) {
      this.props.setSortDirection(newSortDir);
    }

    if (isSortByChanged) {
      this.props.setSortBy(newSortBy);
    }

    if (isPageChanged) {
      this.props.setPage(newPage);
    }

    if (isPageChanged || isSortDirChanged || isSortByChanged) {
      this.props.getArtists();
    }
  };

  onViewImageClick = artist => {
    this.setState({
      isImageModalVisible: true,
      imageUrl: artist.url_full,
    });
  };

  onCloseImageClick = () => {
    this.setState({
      isImageModalVisible: false,
      imageUrl: '',
    });
  };

  onOpenEditArtistModal = artist => {
    this.setState({
      isEditArtistModalOpened: true,
    });

    this.props.setArtistToEdit(artist);
  };

  onCloseEditArtistModal = () => {
    this.setState({
      isEditArtistModalOpened: false,
    });

    this.props.setArtistToEdit(null);
  };

  render() {
    const {
      isLoading,
      artistRows,
      count,
      currentPage,
      columns,
      artistToEdit,
      isAdmin,
    } = this.props;

    return (
      <Layout className="artist-page">
        <Search />

        <Button
          size="default"
          type="primary"
          style={{ marginTop: 20 }}
          onClick={this.props.openCreateArtistModal}
        >
          New Artist
        </Button>

        <Button
          size="default"
          type="primary"
          style={{ margin: '20px 0 20px 20px' }}
          onClick={this.props.openMergeArtistModal}
        >
          Merge Artists
        </Button>
        <Table
          rowKey="id"
          loading={isLoading}
          dataSource={artistRows}
          scroll={{ x: 1950, y: 'calc(100vh - 370px)' }}
          columns={[
            ...columns,
            {
              title: 'View image',
              key: 'view_image',
              dataIndex: 'url_full',
              render: (value, record) => (
                <Button
                  type="primary"
                  icon="file-image"
                  disabled={!value}
                  onClick={() => this.onViewImageClick(record)}
                />
              ),
              fixed: 'right',
              width: 150,
            },
            {
              title: 'Edit',
              key: 'edit',
              dataIndex: 'edit',
              render: (value, record) => (
                <Button
                  type="primary"
                  shape="circle"
                  icon="edit"
                  disabled={!isAdmin && record.artist_status === ARTIST_STATUS.PUBLISHED}
                  onClick={() => this.onOpenEditArtistModal(record)}
                />
              ),
              fixed: 'right',
              width: 100,
            },
          ]}
          size="default"
          pagination={{
            pageSize: ITEMS_PER_PAGE,
            total: count,
            position: 'top',
            current: currentPage,
            size: 'small',
          }}
          onChange={this.handleTableChange}
        />

        {this.state.isImageModalVisible && (
          <Modal
            title="Artist Image"
            width="60%"
            height="400px"
            centered
            visible
            onCancel={this.onCloseImageClick}
            style={{
              textAlign: 'center',
            }}
            footer={[
              <Button key="close" type="primary" onClick={this.onCloseImageClick}>
                Close
              </Button>,
            ]}
          >
            <img
              src={this.state.imageUrl}
              alt="artist"
              style={{ width: 'auto', height: '400px' }}
            />
          </Modal>
        )}

        {artistToEdit && (
          <EditArtistModal
            visible={this.state.isEditArtistModalOpened}
            closeModal={this.onCloseEditArtistModal}
          />
        )}

        {this.props.isCreateArtistModalOpen && (
          <CreateArtistModal
            visible={this.props.isCreateArtistModalOpen}
            closeModal={this.props.closeCreateArtistModal}
          />
        )}

        {this.props.isMergeArtistModalOpen && (
          <MergeArtistModal
            visible={this.props.isMergeArtistModalOpen}
            closeModal={this.props.closeMergeArtistModal}
          />
        )}
      </Layout>
    );
  }
}
