import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

class StartCleaningModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    getLots: PropTypes.func.isRequired,
    startCleaning: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    auctionId: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  startCleaning = async () => {
    await this.props.startCleaning([this.props.auctionId]);
    this.props.getLots();
    this.props.closeModal();
  };

  render() {
    const title = `Start Cleaning`;
    const question = `Are you sure you want to start cleaning this sale?`;
    return (
      <Modal
        title={title}
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        onOk={this.startCleaning}
        okText={'Yes'}
      >
        <p>{question}</p>
      </Modal>
    );
  }
}

export { StartCleaningModal };
