import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';

import {
  setFilters,
  setPage,
  setActiveAuction,
  getLots,
  updateAuctionAPR,
  setIsLoading
} from 'cleaning-lots/actions/cleaning';
// import { updateAuctionAPR } from 'cleaning-lots/actions/admin-cleaning';
import { Search } from 'cleaning-lots/components/CleaningUI/Search';
import { getNotNullFields } from 'shared/tools/form';
import { formatDate, formatIds } from 'shared/helpers/format';
import { denormalizeRows } from '../normalization/denormalize';

const stateToProps = state => ({
  cleaners: denormalizeRows(state.cleaners),
  isAdmin: state.user.userInfo.role.name === 'admin',
  activeAuctionId: state.lots.activeAuctionId,
  rawAuction: state.lots.ids.length ? state.lots.items[state.lots.ids[0]].raw_auction : null,
});

const dispatchToProps = dispatch => ({
  findLots: fields => {
    const filledFields = getNotNullFields({
      ...fields,
      auction_start_date: fields && formatDate(fields.auction_start_date),
      raw_lot_id: fields && fields.raw_lot_id && formatIds(fields.raw_lot_id),
      raw_auction_id: fields && fields.raw_auction_id && formatIds(fields.raw_auction_id),
    });

    // if (filledFields.raw_lot_id) {
    //   dispatch(setActiveAuction(null));
    // }

    dispatch(setPage(1));
    dispatch(setFilters(filledFields));
    dispatch(getLots());
  },
  removeAPR: fields => {
    dispatch(setIsLoading(true));
    dispatch(updateAuctionAPR(fields.raw_auction_id, fields)).then(() => dispatch(getLots()));
  },
});

export default compose(connect(stateToProps, dispatchToProps), Form.create())(Search);
