import { AutoComplete, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import React, { PureComponent } from 'react';

import { ARTIST_STATUS } from 'shared/constants/app';
import { ImageLoader } from 'cleaning-lots/components/CleaningUI/ImageLoader';
import PropTypes from 'prop-types';
import birthDeathTypes from '../../../../../src/utils/birth-death-types';
import nationalities from 'shared/constants/nationalities';

const { TextArea } = Input;

class CreateArtistModal extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    createArtist: PropTypes.func.isRequired,

    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
    }).isRequired,

    artistQualifiers: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    artistQualifiers: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      nationalities,
    };
  }

  onOk = () => {
    const {
      form: { validateFieldsAndScroll },
      createArtist,
    } = this.props;

    validateFieldsAndScroll((err, fields) => {
      if (!err) {
        createArtist(fields);
      }
    });
  };

  filterNationalities = text => {
    if (!text) {
      this.setState({ nationalities });
    }
    let nationalitiesCopy = [...nationalities];
    nationalitiesCopy = nationalitiesCopy.filter(n => n.toLowerCase().includes(text.toLowerCase()));
    this.setState({ nationalities: nationalitiesCopy });
  };

  getFieldValue = fieldName => {
    const formValues = this.props.form.getFieldsValue();
    return formValues[fieldName];
  };

  render() {
    const {
      form: { getFieldDecorator, isFieldsTouched },
      visible,
      closeModal,
      isLoading,
    } = this.props;

    return (
      <Modal
        title="Create Artist"
        visible={visible}
        onCancel={closeModal}
        okText="Create"
        okButtonProps={{
          disabled: !isFieldsTouched() || isLoading,
          onClick: this.onOk,
        }}
        width="50vw"
        bodyStyle={{
          height: '70vh',
          overflowY: 'scroll',
        }}
      >
        {isLoading ? (
          <div className="edit-artist__spin">
            <Spin />
          </div>
        ) : (
          <Form onSubmit={this.onSubmit}>
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item label="Artist Image">
                  {getFieldDecorator('url_full', {
                    initialValue: {
                      fileList: [],
                    },
                  })(<ImageLoader accept="image/*" width="100%" />)}
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item label="Name">
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input artist name',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>

                {this.props.isAdmin && (
                  <Form.Item label="Status">
                    {getFieldDecorator('artist_status', {
                      initialValue: ARTIST_STATUS.PENDING,
                    })(
                      <Select>
                        {this.props.statuses.map(status => (
                          <Select.Option key={status} value={status}>
                            {status}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                )}

                <Form.Item label="Qualifier">
                  {getFieldDecorator('qualifier', {
                    rules: [
                      {
                        required: this.getFieldValue('qualified_artist_id'),
                        message: 'Artist Qualifier required with Qualified Artist ID',
                      },
                    ],
                  })(
                    <Select>
                      <Select.Option value={null}>None</Select.Option>
                      {this.props.artistQualifiers.map(qualifier => (
                        <Select.Option key={qualifier} value={qualifier}>
                          {qualifier}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Qualified Artist Id">
                  {getFieldDecorator('qualified_artist_id', {
                    rules: [
                      {
                        required: this.getFieldValue('qualifier'),
                        message: 'Qualified Artist ID required with Qualifier',
                      },
                    ],
                  })(<Input type="number" />)}
                </Form.Item>
                <Form.Item label="Nationality">
                  {getFieldDecorator('nationality')(
                    <AutoComplete
                      dataSource={this.state.nationalities}
                      onSearch={this.filterNationalities}
                      placeholder="Nationality"
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Birth / Established">
                  {getFieldDecorator('birth')(<Input type="number" />)}
                </Form.Item>

                <Form.Item label="Death">
                  {getFieldDecorator('death')(<Input type="number" />)}
                </Form.Item>
                <Form.Item label="Birth/Death type">
                  {getFieldDecorator('birth_death_type', {
                    initialValue: 'exact',
                  })(
                    <Select>
                      {Object.keys(birthDeathTypes).map(value => (
                        <Select.Option key={value} value={value}>
                          {birthDeathTypes[value]}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Alternate Spellings">
                  {getFieldDecorator('alternate_spellings')(
                    <TextArea autosize={{ minRows: 1, maxRows: 4 }} />,
                  )}
                </Form.Item>
                <Form.Item label="Active Date">
                  {getFieldDecorator('active_date')(<Input maxLength={120} />)}
                </Form.Item>

                <Form.Item label="Alternate DoB">
                  {getFieldDecorator('alternate_birth')(<Input type="number" />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  }
}

export default CreateArtistModal;
