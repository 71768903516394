import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { setSelectedAuctions, reAssignToClean } from 'cleaning-lots/actions/admin-cleaning';
import { ReAssignModal } from 'cleaning-lots/components/AdminCleaningUI/ReAssignModal';

const stateToProps = state => ({});

const dispatchToProps = {
  reAssignToClean,
  setSelectedAuctions,
};

export default compose(connect(stateToProps, dispatchToProps), Form.create())(ReAssignModal);
